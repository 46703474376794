import { css } from "@emotion/react";
import { Grid, Button } from "antd";
import { Image } from "lib/imgproxy";
import { Skeleton } from "./Skeleton";
import { typography } from "config/theme";

const styles = {
    rootContainer: css({
        width: "100%",
        borderRadius: 7,
        overflow: "hidden",
        background: "#fff",
    }),
    border: css({ border: "1px solid #e8e8e8" }),
    cursor: css({ cursor: "pointer" }),
    ellipsis: css({
        overflow: "hidden",
        lineHeight: 1.4,
        display: "-webkit-box",
        lineClamp: 2,
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical",
    }),
    imageContainer: css({ padding: 0, width: "100%" }),
    imagePlaceholder: css({
        gridArea: "image",
        position: "relative",
        overflow: "hidden",
        background: "rgba(103, 129, 190, 0.3)",
        paddingBottom: "75%",
        borderRadius: "5px",
    }),
    image: css({ width: "100%", position: "absolute", top: 0, left: 0 }),
    textContainer: css({
        gridArea: "text",
        padding: "12px",
    }),
    topTextContainer: css({
        fontWeight: 700,
        color: "#0043ff",
        lineClamp: 1,
        "-webkit-line-clamp": "1",
        "@media (max-width: 768px)": {
            margin: "8px 0 6px",
        },
    }),
    midTextContainer: css({
        fontWeight: "bold",
        fontSize: "17px",
        lineHeight: 1.5,
    }),
    buttonContainer: css({
        padding: "0 12px",
        gridArea: "buttons",
        display: "flex",
        justifyContent: "center",
        gap: 16,
    }),
};

type CentreCardProps = {
    className?: string;
    loading?: boolean;
    image?: string;
    imageAlt?: string;
    bordered?: boolean;
    mode?: "vertical" | "horizontal";
    topText?: string;
    middleText?: string;
    bottomText?: string;
    leftButtonText?: string;
    rightButtonText?: string;
    onCardClick?: () => void;
    onLeftButtonClick?: () => void;
    onRightButtonClick?: () => void;
};

const CentreCard = ({
    className,
    loading,
    image = "/images/default_centre.jpg",
    imageAlt = "Image of Sports Centre",
    bordered = true,
    mode: propMode,
    topText,
    middleText,
    bottomText,
    leftButtonText,
    rightButtonText,
    onCardClick,
    onLeftButtonClick,
    onRightButtonClick,
}: CentreCardProps): JSX.Element => {
    let mode = propMode;
    const { lg } = Grid.useBreakpoint();
    if (!mode) {
        mode = lg ? "horizontal" : "vertical";
    }
    let buttonGridAreaRow = "45px";
    if (!onLeftButtonClick && !onRightButtonClick) {
        buttonGridAreaRow = "0px";
    }
    const isVertical = mode === "vertical";
    const handleRightButtonClick = (event): void => {
        // Stop the click event from propagating to centre details
        event.stopPropagation();
        onRightButtonClick?.();
    };
    return (
        <div
            css={[
                styles.rootContainer,
                bordered && styles.border,
                onCardClick && styles.cursor,
                loading && {
                    ".centre-card-skeleton-loading": {
                        padding: "12px",
                    },
                },
            ]}
            className={className}
            onClick={onCardClick}
        >
            <Skeleton loading={!!loading} rows={4}>
                <div
                    css={[
                        isVertical
                            ? {
                                  gridTemplateAreas: "'image' 'text' 'buttons'",
                                  gridTemplateRows: `minmax(120px, 1fr) 135px ${buttonGridAreaRow}`,
                              }
                            : {
                                  gridTemplateAreas:
                                      "'text image' 'buttons buttons'",
                                  gridTemplateColumns: "3fr 2.3fr",
                                  gridTemplateRows: "150px 30px",
                              },
                        {
                            display: "grid",
                        },
                    ]}
                    className="centre-card"
                >
                    <div css={styles.imageContainer}>
                        <div
                            css={styles.imagePlaceholder}
                            className="centre-card-image-placeholder"
                        >
                            <Image
                                css={styles.image}
                                src={image}
                                layout="fill"
                                objectFit="cover"
                                sizes="(min-width: 320px) 300px, (min-width: 1024px) 380px, 80vw"
                                alt={imageAlt}
                            />
                        </div>
                    </div>
                    <div
                        css={styles.textContainer}
                        className="centre-card-text-container"
                    >
                        {topText && (
                            <div
                                css={[
                                    styles.ellipsis,
                                    typography.tinyContent,
                                    styles.topTextContainer,
                                ]}
                            >
                                {topText.toUpperCase()}
                            </div>
                        )}
                        {middleText && (
                            <div
                                css={[styles.ellipsis, styles.midTextContainer]}
                                className="centre-card-middle-text"
                            >
                                {middleText}
                            </div>
                        )}
                        {bottomText && (
                            <div
                                css={[styles.ellipsis, { fontSize: "15px" }]}
                                className="centre-card-bottom-text"
                            >
                                {bottomText}
                            </div>
                        )}
                    </div>
                    <div
                        css={styles.buttonContainer}
                        className="centre-card-button-container"
                    >
                        {onLeftButtonClick && (
                            <Button
                                block
                                ghost
                                type="primary"
                                onClick={onLeftButtonClick}
                            >
                                {leftButtonText}
                            </Button>
                        )}
                        {onRightButtonClick && (
                            <Button
                                block
                                type="primary"
                                onClick={handleRightButtonClick}
                            >
                                {rightButtonText}
                            </Button>
                        )}
                    </div>
                </div>
            </Skeleton>
        </div>
    );
};

export default CentreCard;
