import { css } from "@emotion/react";
import { Grid, Button, Typography } from "antd";
import { RightOutlined, BulbOutlined } from "@ant-design/icons";
import { Image } from "lib/imgproxy";
import { useTranslation } from "react-i18next";

const styles = {
    rootContainer: css({
        width: "100%",
        boxShadow: "none",
        overflow: "hidden",
        background: "#fff",
    }),
    divider: css({ margin: "12px 0" }),
    imageContainer: css({ position: "relative", width: 300, height: 200 }),
    opacity: css({ opacity: 0.6 }),
    flexAlignCenter: css({ display: "flex", alignItems: "center" }),
    detailsContainer: css({ display: "flex", gap: 8, flexWrap: "wrap" }),
    middleTextContainer: css({ flex: "1 1 200px", marginBottom: "12px" }),
    lightTextContainer: css({
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: "12px",
        padding: "16px 8px 16px 8px",
        backgroundColor: "#E0E7FF",
        borderRadius: "8px",
        boxShadow:
            "0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1)",
    }),
    addressLayout: css({
        display: "grid",
        gridTemplateColumns: "1fr 32px",
        alignItems: "center",
    }),
};
type BookingDetailsCentreCard = {
    className?: string;
    image?: string;
    hasUserActivation?: boolean;
    imageAlt?: string;
    topText?: string;
    middleText?: string;
    location: { latitude: number; longitude: number };
};

const BookingDetailsCentreCard = ({
    className,
    image,
    hasUserActivation = false,
    imageAlt = "centre",
    topText,
    middleText,
    location,
}: BookingDetailsCentreCard): JSX.Element => {
    const screens = Grid.useBreakpoint();
    const isDesktop = !!screens.lg;
    const { t, ready } = useTranslation("components/CentreCard");
    if (!ready) return <div />;
    return (
        <div css={styles.rootContainer} className={className}>
            <div css={{ display: "flex", gap: 16 }}>
                {isDesktop && image && (
                    <div
                        css={styles.imageContainer}
                        className="booking-details-image"
                    >
                        <Image
                            src={image}
                            layout="fill"
                            objectFit="cover"
                            sizes="(min-width: 320px) 300px, (min-width: 1024px) 380px, 80vw"
                            alt={imageAlt}
                            css={{ borderRadius: 7 }}
                        />
                    </div>
                )}
                <div css={[{ flex: 1, marginTop: 12 }]}>
                    <>
                        {topText && (
                            <div
                                css={{
                                    fontWeight: "bold",
                                    fontSize: 20,
                                    lineHeight: 1.4,
                                }}
                                className="booking-details-top-text"
                            >
                                {topText}
                            </div>
                        )}
                        <div css={styles.detailsContainer}>
                            {middleText && (
                                <div
                                    css={styles.middleTextContainer}
                                    className="booking-details-middle-text"
                                >
                                    {middleText}
                                </div>
                            )}
                            {isDesktop && (
                                <div css={{ marginBottom: "12px" }}>
                                    <DirectionsButton
                                        isDesktop={isDesktop}
                                        location={location}
                                    />
                                </div>
                            )}
                            {hasUserActivation && (
                                <div css={styles.lightTextContainer}>
                                    <BulbOutlined
                                        style={{ fontSize: "32px" }}
                                    />
                                    <div>
                                        <Typography.Title level={4}>
                                            {t("light.title", {
                                                defaultValue:
                                                    "Indicate Your Playtime with Ease!",
                                            })}
                                        </Typography.Title>
                                        <Typography.Text
                                            style={{ lineHeight: "140%" }}
                                        >
                                            {t("light.description", {
                                                defaultValue:
                                                    "Tap the button to activate the session light. Help us ensure a smooth experience for all players!",
                                            })}
                                        </Typography.Text>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};

export default BookingDetailsCentreCard;

type ShareAndDirectionsButtonsProps = {
    isDesktop: boolean;
    location: { latitude: number; longitude: number };
};
export const DirectionsButton = ({
    isDesktop,
    location,
}: ShareAndDirectionsButtonsProps): JSX.Element => {
    const { t, ready } = useTranslation("components/CentreCard");
    const onGMapsNav = (): void => {
        const url = new URL("https://www.google.com/maps/search/");
        const { latitude, longitude } = location;
        url.search = new URLSearchParams({
            api: "1",
            query: [latitude, longitude].join(","),
        }).toString();
        open(url);
    };
    const onWazeNav = (): void => {
        const url = new URL("https://waze.com/ul");
        const { latitude, longitude } = location;
        url.search = new URLSearchParams({
            navigate: "yes",
            ll: [latitude, longitude].join(","),
        }).toString();
        open(url);
    };
    if (!ready) return <div />;
    if (isDesktop)
        return (
            <Button
                type="primary"
                ghost
                css={{ fontWeight: "normal" }}
                onClick={onGMapsNav}
            >
                {t("open_maps.google", {
                    defaultValue: "Open in Google Maps",
                })}
            </Button>
        );
    return (
        <>
            <div
                onClick={onGMapsNav}
                css={{
                    cursor: "pointer",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                }}
            >
                <div>
                    {t("open_maps.google", {
                        defaultValue: "Open in Google Maps",
                    })}
                </div>
                <div css={{ marginRight: 36 }}>
                    {" "}
                    <RightOutlined />
                </div>
            </div>
            <div
                css={{
                    borderTop: "1px solid rgba(0,0,0,.06)",
                    margin: "24px 0",
                }}
            />{" "}
            <div
                onClick={onWazeNav}
                css={{
                    cursor: "pointer",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                }}
            >
                <div>
                    {t("open_maps.waze", {
                        defaultValue: "Open in Waze",
                    })}
                </div>
                <div css={{ marginRight: 36 }}>
                    {" "}
                    <RightOutlined />
                </div>
            </div>
            <div
                css={{
                    borderTop: "1px solid rgba(0,0,0,.06)",
                    margin: "24px 0",
                }}
            />{" "}
        </>
    );
};
