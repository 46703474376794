import { keyframes } from "@emotion/react";
import React from "react";

const skeletonLoading = keyframes({
    "0%": {
        backgroundColor: "rgba(190, 190, 190, 0.2)",
    },
    "100%": {
        backgroundColor:
            "linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%)",
    },
});

export const Skeleton = ({
    rows,
    loading,
    children,
}: {
    rows: number;
    loading: boolean;
    children?: React.ReactElement;
}): JSX.Element => {
    return (
        <div className="centre-card-skeleton-loading">
            {loading
                ? Array.from(Array(rows).keys()).map((row) => (
                      <div
                          key={row}
                          css={{
                              ":first-child": {
                                  width: "38%",
                              },
                              ":last-child": {
                                  width: "62%",
                              },
                              height: 16,
                              margin: "16px 0",
                              borderRadius: 5,
                              background:
                                  "linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%)",
                              animation: `${skeletonLoading} 1000ms ease infinite`,
                          }}
                      />
                  ))
                : children}
        </div>
    );
};
