export type Centre = {
    uid: string;
    name: string;
    cover?: string;
    cityAndState?: string;
    isCourtsitePartner?: boolean;
    imagesWithTags?: { url: string; tags: string[] }[];
};
export const renderCentreImage = (
    centre: Centre,
    searchCategoryId?: string,
): string => {
    const centreImagesWithTags = centre.imagesWithTags ?? [];
    if (!centreImagesWithTags.length)
        return centre.cover || "/images/default_centre.jpg"; //to check with product if all fully migrated

    const filterImg = (type?: string): string =>
        centreImagesWithTags.filter(
            (image) => type !== undefined && image.tags.includes(type),
        )[0]?.url ?? (centreImagesWithTags[0]?.url as string);

    let centreImg = filterImg("all");
    if (searchCategoryId) {
        centreImg = filterImg(searchCategoryId);
    }
    return centreImg;
};
